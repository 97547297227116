<div class="tabs">
  <ng-container *ngFor="let tab of tabs">
      <span
        class="tab"
        [ngClass]="{'selected': tab.elementId == selectedTab?.elementId}"
        (click)="setSelectedTab(tab)">
      {{ (tab.title | translate) || tab.title }}
    </span>
  </ng-container>
</div>
