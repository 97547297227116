import { Component, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthorizeService } from '@common/co/auth/services/authorize.service';
import { InTakeService } from '@common/co/auth/services/in-take.service';
import {
  RegisterCommand,
  UserRegistrationFlow,
} from '@common/services/co-api-client';
import { Router } from '@angular/router';
import { commonNavigationConfig } from '@common/co/navigation/navigation';
import { takeUntil } from 'rxjs/operators';
import { DateTimeHelper } from '@common/shared/helpers/date-time.helper';
import { TranslateService } from '@ngx-translate/core';

enum StepType {
  Default,
  UniqueId,
  PersonalInfo,
  Password,
  WaiverOfLiability,
  Complete,
}

enum RegistrationType {
  Default,
  InvalidEmail,
  UseUniqueId,
}

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class RegisterComponent implements OnInit, OnDestroy {
  stepType = StepType;
  currentStep: StepType = StepType.Default;
  stepsHistory: StepType[] = [];
  userSubscription: Subscription;
  registerFields: RegisterCommand = new RegisterCommand();
  userSocialLogin: boolean;
  registrationType = RegistrationType;
  currentRegistrationType: RegistrationType = RegistrationType.Default;

  private _unsubscribeAll: Subject<void>;
  // Private

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _authService: AuthorizeService,
    private _inTakeService: InTakeService,
    private _router: Router,
    private _translateService: TranslateService,
  ) {
    this._unsubscribeAll = new Subject();
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
    if (_authService.IsUserRegistrationNotCompleted) {
      this.userSubscription = _authService.user$.subscribe((u) => {
        this.registerFields.email = u.email;
        this.userSocialLogin = !!u.email;
        this.registerFields.firstName = u.firstName;
        this.registerFields.lastName = u.lastName;
      });
    }
  }

  ngOnInit(): void {
    this._inTakeService
      .isInTakePassed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((inTakePassed) => {
        if (inTakePassed) {
          this._router.navigateByUrl(commonNavigationConfig.dashboard.url, {
            replaceUrl: true,
          });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  registerModel: RegisterCommand = new RegisterCommand({});

  /**
   * On Register
   */
  async onRegister(): Promise<void> {
    if (this.registerFields.userRegistrationValidation?.dateOfBirth) {
      this.registerFields.userRegistrationValidation.dateOfBirth =
        DateTimeHelper.getDateWithAddedOffset(
          this.registerFields.userRegistrationValidation.dateOfBirth,
        );
    }
    this.registerFields.locale = this._translateService.currentLang;
    await this._authService.registerUser(this.registerFields);
    return Promise.resolve();
  }

  async next(data?: RegisterCommand): Promise<void> {
    this.stepsHistory.push(this.currentStep);
    switch (this.currentStep) {
      case StepType.Default:
        this.assignData(data);
        if (
          data?.userRegistrationFlow === UserRegistrationFlow.ByUniqueId &&
          this.currentRegistrationType !== RegistrationType.UseUniqueId
        ) {
          this.currentRegistrationType = RegistrationType.InvalidEmail;
          this.currentStep = StepType.UniqueId;
        } else {
          this.currentStep = StepType.PersonalInfo;
        }
        break;
      case StepType.UniqueId:
        this.assignData(data);
        if (this.currentRegistrationType === RegistrationType.UseUniqueId) {
          this.currentStep = StepType.Default;
        } else {
          this.currentStep = StepType.PersonalInfo;
        }
        break;
      case StepType.PersonalInfo:
        this.assignData(data);
        this.currentStep += 1;
        if (this.userSocialLogin) {
          this.currentStep += 1;
        }
        break;
      case StepType.Password:
        this.assignData(data);
        this.currentStep += 1;
        break;
      case StepType.WaiverOfLiability:
        this.assignData(data);
        await this.onRegister();
        this.currentStep += 1;
        break;
    }
    return Promise.resolve();
  }

  private assignData(data): void {
    this.registerFields = Object.assign(this.registerFields, data);
  }

  public createUseUniqueId(): void {
    this.currentRegistrationType = RegistrationType.UseUniqueId;
    this.assignData({ userRegistrationFlow: UserRegistrationFlow.ByUniqueId });
    this.currentStep = StepType.UniqueId;
  }

  async navigateToIntake(): Promise<void> {
    await this._inTakeService.navigateToIntake();
    return Promise.resolve();
  }

  previous(): void {
    if (
      this.currentStep === StepType.PersonalInfo &&
      this.registerFields?.userRegistrationFlow === UserRegistrationFlow.ByEmail
    ) {
      this.currentStep = StepType.Default;
      this.checkDefaultStep();
      return;
    }
    if (this.stepsHistory?.length === 0) {
      this.currentStep -= 1;
    } else {
      this.currentStep = this.prevStep;
      this.stepsHistory.pop();
    }
    this.checkDefaultStep();
  }

  public get prevStep(): StepType {
    if (this.stepsHistory.length === 0) return undefined;
    return this.stepsHistory[this.stepsHistory.length - 1];
  }

  private checkDefaultStep(): void {
    if (
      this.currentStep === StepType.Default &&
      this.prevStep !== StepType.UniqueId
    ) {
      this.currentRegistrationType = RegistrationType.Default;
      this.registerFields.userRegistrationFlow = undefined;
    }
  }
}
